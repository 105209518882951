import ReferralClient from '../clients/Referral.client'

/** mutation types */
export const set_referrals = 'set_referrals'
export const set_referral = 'set_referral'

/** state */
const getDefaultState = () => {
  return {
    referrals: null,
  }
}
const state = getDefaultState()

/** getters */
const getters = {
  referrals: (state) => state.referrals,
}

/** mutations */
const mutations = {
  [set_referrals] (state, data) {
    state.referrals = data
  },
}

/** actions */
const actions = {
  getReferrals: ({ commit }) => {
    return new ReferralClient()
      .getReferrals()
      .then((response) => {
        commit(set_referrals, response.data)
        return Promise.resolve(response.data)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
