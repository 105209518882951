import { BaseClient } from '@simplifyd-systems/lib.client.core'

export default class BaseClientWithApi extends BaseClient {
  constructor (options = {}) {
    const domain = process.env.VUE_APP_API_DOMAIN
    const config = {
      baseURL: domain,
      ...options,
    }
    super(config)
  }
}
