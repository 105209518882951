import BaseAuthClientWithApi from './BaseAuth.client'

export default class TraningClient extends BaseAuthClientWithApi {
  getTrainingData () {
    return this
      .client
      .get('/v1/llm/training/data')
  }

  createTrainingData (payload) {
    return this
      .client
      .post('/v1/llm/training/data', payload)
  }

  deleteTrainingData (id) {
    return this
      .client
      .delete(`/v1/llm/training/data/${id}`)
  }
}
