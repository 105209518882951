import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)
const simplifydColor = {
  primary: '#4A64FF',
  secondary: '#FD9F00',
  accentLight: '#E8EBFF',
  bg: '#F8FAFB',
  border: '#DFE2E6',
  grayText: '#B9BCC8',
  grayTextAlt: '#59617B',
  accent: '#EC3944',
  dark: '#000000',
  lightGray: '#F5F7FA',
  light: '#fff',
  lightAlt: '#F6F7F9',
  dangerAlt: '#ffeaeb',
  success: '#4CAF50',
  successAlt: '#eaf7ec',
  defaultIconColor: '#737a91',
  gray: '#737A91',
  grayBg: '#F6F7F9',
  blueLight: '#437FEC',
  black: '#222222',
}

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: { ...simplifydColor },
    },
  },
})
