import MailClient from '../clients/Mail.client'

/** mutation types */
export const set_mails = 'set_mails'
export const set_mail = 'set_mail'

/** state */
const getDefaultState = () => {
  return {
    mails: null,
  }
}
const state = getDefaultState()

/** getters */
const getters = {
  mails: (state) => state.mails,
}

/** mutations */
const mutations = {
  [set_mails] (state, data) {
    state.mails = data
  },
}

/** actions */
const actions = {
  getMails: ({ commit }) => {
    return new MailClient()
      .getMails()
      .then((response) => {
        commit(set_mails, response.data)
        return Promise.resolve(response.data)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
