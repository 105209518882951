/**
 * Actions:
 * [showError]: show snackbar w/ error style
 * [showSuccess]: show snackbar w/ success style
 * [showInfo]: show snackbar w/ info style
 * [clearMessage]: clear current snackbar message
 */

/** mutation types */
export const set_success = 'set_success'
export const set_error = 'set_error'
export const set_info = 'set_info'
export const clear_message = 'clear_message'

/** state */
const state = {
  currentMessage: false,
}

/** getters */
const getters = {
  currentMessage: (state) => state.currentMessage,
}

/** mutations */
const mutations = {
  [set_success] (state, message) {
    state.currentMessage = { ...message, type: 'success' }
  },
  [set_error] (state, message) {
    state.currentMessage = { ...message, type: 'error' }
  },
  [set_info] (state, message) {
    state.currentMessage = { ...message, type: 'info' }
  },
  [clear_message] (state) {
    state.currentMessage = false
  },
}

/** actions */
const actions = {
  showError ({ commit }, message) {
    commit(set_error, message)
  },
  showSuccess ({ commit }, message) {
    commit(set_success, message)
  },
  showInfo ({ commit }, message) {
    commit(set_info, message)
  },
  clearMessage ({ commit }) {
    commit(clear_message)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
