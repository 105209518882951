
// Import HTTP client
import AuthClient from '../clients/Auth.client'

/** mutation types */
// eslint-disable-line
const set_user = 'set_user'

/** state */
const state = {
  user: {},
}

/** getters */
const getters = {
  user: state => state.user,
}

/** mutations */
const mutations = {
  [set_user] (state, data) {
    state.user = data
  },
}

/** actions */
const actions = {
  login: ({ commit }, payload) => {
    return new AuthClient()
      .login(payload)
      .then(response => {
        localStorage.setItem('jwt', response.data.jwt)
        const user = response.data.account
        commit(set_user, user)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
