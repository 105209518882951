import TrainingClient from '../clients/Training.client'

/** mutation types */
export const set_data = 'set_data'
export const set_dat = 'set_dat'
export const delete_data = 'delete_data'

/** state */
const getDefaultState = () => {
  return {
    data: null,
  }
}
const state = getDefaultState()

/** getters */
const getters = {
  data: (state) => state.data,
}

/** mutations */
const mutations = {
  [set_data] (state, data) {
    state.data = data
  },
  [set_dat] (state, data) {
    state.data = [...data, ...state.data]
  },
  [delete_data] (state, id) {
    const index = state.data.findIndex((data) => data.id === id)
    state.data.splice(index, 1)
  },
}

/** actions */
const actions = {
  createTrainingData: ({ commit }, payload) => {
    return new TrainingClient()
      .createTrainingData(payload)
      .then((response) => {
        commit(set_dat, [payload])
        return Promise.resolve(response.data)
      })
  },
  getTrainingData: ({ commit }) => {
    return new TrainingClient()
      .getTrainingData()
      .then((response) => {
        commit(set_data, response.data)
        return Promise.resolve(response.data)
      })
  },
  deleteTrainingData: ({ commit }, id) => {
    return new TrainingClient()
      .deleteTrainingData(id)
      .then((response) => {
        commit(delete_data, id)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
